import { Routes } from '@angular/router';

import { AdminModuleHeaderComponent } from '@offconon/admin/features/design-helper/admin-module-header';
import { AdminModuleMenuComponent } from '@offconon/admin/features/design-helper/admin-module-menu';
import { AdminModuleSelectorComponent } from '@offconon/admin/features/design-helper/admin-module-selector';
import { LayoutResolverService } from '@offconon/shared/features/layout';
import { SectionsComponent } from '@offconon/shared/ui/scroller-section';

export const AdminFeaturesCategoryTemplateAdminRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/category-template-admin/category-template-admin.component').then(
        (m) => m.CategoryTemplateAdminComponent,
      ),
    resolve: { layout: LayoutResolverService },
    data: {
      layout: {
        leftBottom: {
          component: AdminModuleMenuComponent,
          inputs: { activepage: '/category-template-admin', app_enum: 'category' },
        },
        rightBottom: {
          component: AdminModuleSelectorComponent,
        },

        contentHeader: {
          component: AdminModuleHeaderComponent,
          inputs: {
            moduleData: {
              title: 'Template categories',
              subtitle: 'OffConOn Administration',

              backgroundImage: '/assets/images/main_header/garden_back.jpg',
              menuItems: [
                { icon: 'pi pi-home', route: '/category-template-admin' },
                { label: 'OffConOn Administration' },
                { label: 'Template categories' },
              ],
            },
          },
        },
      },
    },
  },
  {
    path: 'filter/:id',

    loadComponent: () =>
      import('./pages/category-template-admin/category-template-admin.component').then(
        (m) => m.CategoryTemplateAdminComponent,
      ),
    resolve: { layout: LayoutResolverService },
    data: {
      layout: {
        leftBottom: {
          component: AdminModuleMenuComponent,
          inputs: { activepage: '/category-template-admin', app_enum: 'category' },
        },
        rightBottom: {
          component: AdminModuleSelectorComponent,
        },

        contentHeader: {
          component: AdminModuleHeaderComponent,
          inputs: {
            moduleData: {
              title: 'Template categories',
              subtitle: 'OffConOn Administration',

              backgroundImage: '/assets/images/main_header/garden_back.jpg',
              menuItems: [
                { icon: 'pi pi-home', route: '/category-template-admin' },
                { label: 'OffConOn Administration' },
                { label: 'Template categories' },
              ],
            },
          },
        },
      },
    },
  },
  {
    path: 'settings/:id',
    loadComponent: () =>
      import(
        './pages/category-template-admin-settings/category-template-admin-settings.component'
      ).then((m) => m.CategoryTemplateAdminSettingsComponent),
    resolve: { layout: LayoutResolverService },
    data: {
      layout: {
        leftBottom: {
          component: AdminModuleMenuComponent,
          inputs: { activepage: '/category-template-admin', app_enum: 'category' },
        },
        rightBottom: {
          component: SectionsComponent,
        },

        contentHeader: {
          component: AdminModuleHeaderComponent,
          inputs: {
            moduleData: {
              title: 'Template categories',
              subtitle: 'Settings',

              backgroundImage: '/assets/images/main_header/garden_back.jpg',
              menuItems: [
                { icon: 'pi pi-home', route: '/category-template-admin' },
                { label: 'OffConOn Administration' },
                { label: 'Template categories' },
                { label: 'Settings' },
              ],
            },
          },
        },
      },
    },
  },
  {
    path: 'create',
    loadComponent: () =>
      import('./pages/category-template-create/category-template-create.component').then(
        (m) => m.CategoryTemplateCreateComponent,
      ),
    resolve: { layout: LayoutResolverService },
    data: {
      layout: {
        leftBottom: {
          component: AdminModuleMenuComponent,
          inputs: { activepage: '/category-template-admin', app_enum: 'category' },
        },
        rightBottom: {
          component: AdminModuleSelectorComponent,
        },

        contentHeader: {
          component: AdminModuleHeaderComponent,
          inputs: {
            moduleData: {
              title: 'Template categories',
              subtitle: 'Create',

              backgroundImage: '/assets/images/main_header/garden_back.jpg',
              menuItems: [
                { icon: 'pi pi-home', route: '/category-template-admin' },
                { label: 'OffConOn Administration' },
                { label: 'Template categories' },
                { label: 'Create' },
              ],
            },
          },
        },
      },
    },
  },

  {
    path: 'add-subcategory/:parent_id',
    loadComponent: () =>
      import('./pages/category-template-create/category-template-create.component').then(
        (m) => m.CategoryTemplateCreateComponent,
      ),
    resolve: { layout: LayoutResolverService },
    data: {
      layout: {
        leftBottom: {
          component: AdminModuleMenuComponent,
          inputs: { activepage: '/category-template-admin', app_enum: 'category' },
        },
        rightBottom: {
          component: AdminModuleSelectorComponent,
        },

        contentHeader: {
          component: AdminModuleHeaderComponent,
          inputs: {
            moduleData: {
              title: 'Template categories',
              subtitle: 'Create',

              backgroundImage: '/assets/images/main_header/garden_back.jpg',
              menuItems: [
                { icon: 'pi pi-home', route: '/category-template-admin' },
                { label: 'OffConOn Administration' },
                { label: 'Template categories' },
                { label: 'Create' },
              ],
            },
          },
        },
      },
    },
  },
];
