import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of, filter, map, mergeMap, tap, concat } from 'rxjs';

import { SearchService } from '@offconon/admin-api';

import * as CategoryTemplateAdminActions from './category-template-admin.actions';
import { selectCategoryById } from './category-template-admin.selectors';

@Injectable()
export class CategoryTemplateAdminEffects {
  private actions$ = inject(Actions);
  private searchService = inject(SearchService);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);
  private store = inject(Store);

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryTemplateAdminActions.loadCategoryTemplateAdmin),
      switchMap((action) => {
        // First dispatch loading state and clear selected category
        return concat(
          of(CategoryTemplateAdminActions.setLoadingState({ loading: true })),
          of(CategoryTemplateAdminActions.clearSelectedCategory()),
          this.searchService
            .searchCategoryTemplateList(action.page, action.pageSize, action.searchJson)
            .pipe(
              map((categoryTemplateAdmin: any) =>
                CategoryTemplateAdminActions.loadCategoryTemplateAdminSuccess({
                  categoryTemplateAdmin,
                }),
              ),
              catchError((error) =>
                of(CategoryTemplateAdminActions.loadCategoryTemplateAdminFailure({ error })),
              ),
            ),
        );
      }),
    ),
  );
  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryTemplateAdminActions.deleteCategory),

      switchMap((action) => {
        return this.searchService.searchCategoryTemplateDestroy(action.id).pipe(
          switchMap(() => {
            this.messageService.add({
              severity: 'info',
              summary: this.translateService.instant('Delete'),
              detail: this.translateService.instant('The item has been deleted!'),
            });
            return of(
              CategoryTemplateAdminActions.deleteCategorySuccess({
                id: action.id,
              }),
            );
          }),
          catchError((error) =>
            of(CategoryTemplateAdminActions.loadCategoryTemplateAdminFailure({ error })),
          ),
        );
      }),
    ),
  );

  fetchCategoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryTemplateAdminActions.fetchCategoryById),
      tap((action) => {}),
      filter((action) => !!action.id),

      mergeMap((action) =>
        this.store
          .select(selectCategoryById(action.id as number))
          .pipe(map((category) => ({ action, category }))),
      ),
      mergeMap(({ action, category }) => {
        // If category exists in store, dispatch success action immediately
        if (category) {
          return of(
            CategoryTemplateAdminActions.fetchCategoryByIdSuccess({
              category,
            }),
          );
        }

        // If category doesn't exist, fetch from API

        return this.searchService
          .searchCategoryTemplateList(1, 1, {
            id: action.id,
            language_id: action.languageId,
          })
          .pipe(
            switchMap((result) => {
              return of(
                CategoryTemplateAdminActions.fetchCategoryByIdSuccess({
                  category: result?.results?.[0],
                }),
              );
            }),
            catchError((error) =>
              of(
                CategoryTemplateAdminActions.loadCategoryTemplateAdminFailure({
                  error,
                }),
              ),
            ),
          );
      }),
    ),
  );
}
